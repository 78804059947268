export const formatAmount = (x: number) => {
  if (x === null || x === undefined) {
    return ''
  }
  return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatNumber = (x: number) => {
  if (x === null || x === undefined) {
    return ''
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const htmlfyJsonString = (jsonString: string) => {
  return jsonString.split('\n')
}

export const getPhoto = (prefix: string, imageUrl: string) => {
  return require(`../assets/images/${prefix}/${imageUrl}`)
}
